import React from 'react';
import './style/main.less';
import { ProductsPage } from './pages/Products';
import logo from './images/InnoTactLogoDark.png';
import {
  MainLayout,
  Page,
  AuthenticatedRoute,
  InjectApolloClient,
  TranslationsPage,
  JobsPage,
  GraphQLPage
} from 'lib';
import { CollectionsPage } from 'pages/Collections';
const Logo: React.FC = () => (
  <div className="logo">
    <img src={logo} alt="logo" />
  </div>
);

const pages: Page[] = [
  {
    label: 'Products',
    key: 'products',
    routeComponent: AuthenticatedRoute,
    render: () => (
      <InjectApolloClient>
        <ProductsPage></ProductsPage>
      </InjectApolloClient>
    )
  },
  {
    label: 'Translations',
    key: 'translations',
    routeComponent: AuthenticatedRoute,
    render: () => (
      <InjectApolloClient>
        <TranslationsPage
          langs={{
            sv: 'Swedish',
            en: 'English',
            ru: 'russian'
          }}
        />
      </InjectApolloClient>
    )
  },
  {
    label: 'Collections',
    key: 'collections',
    routeComponent: AuthenticatedRoute,
    render: () => (
      <InjectApolloClient>
        <CollectionsPage />
      </InjectApolloClient>
    )
  },
  {
    label: 'Jobs',
    key: 'jobs',
    routeComponent: AuthenticatedRoute,
    render: () => (
      <InjectApolloClient>
        <JobsPage />
      </InjectApolloClient>
    )
  },
  {
    label: 'Console',
    key: 'graphql-console',
    routeComponent: AuthenticatedRoute,
    render: () => (
      <InjectApolloClient>
        <GraphQLPage />
      </InjectApolloClient>
    )
  }
];

export const App: React.FunctionComponent = () => (
  <MainLayout pages={pages} logo={Logo} />
);
